import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';
import TiktokPixel from 'tiktok-pixel';

// import ReactPixel from 'react-facebook-pixel';
import Home from '../pages/Home';
import Page404 from '../pages/404';
import Contact from '../pages/Contact';
import Category from '../pages/Category';
import ThankYou from '../pages/ThankYou';
import Collection from '../pages/CollectionSingleCategory';

import SpecialEvent from '../pages/SpecialEvent';
import KioskService from '../pages/KioskService';
import WithAuthRoute from './WithAuthRoutes';
import RegularProduct from '../pages/Product';
import CustomizedProduct from '../pages/Design';
import CustomizedProducts from '../pages/Designs';

import Static from '../pages/Static';
import Account from '../pages/Account';
import Checkout from '../pages/Checkout';
import EditSession from '../pages/EditTool';
import CreationSession from '../pages/CreateTool';
import CollectionAllCategories from '../pages/CollectionAllCategories';
import useCurrentUser from '../utils/useCurrentUser';
import { useUserProvider } from '../context/user';

import CreateNewPassword from '../pages/CreateNewPassword';

const googleTagsPageViewHandler = () => {
  // @ts-ignore
  window.dataLayer.push({
    event: 'Pageview',
    pagePath: `${window.location.href}`,
  });
};

const snapPixelPageViewHandler = (user: any) => {
  // @ts-ignore
  window.snaptr('track', 'PAGE_VIEW', {
    item_ids: [],
    item_category: window.location.href,
    uuid_c1: '',
    user_email: user?.email || '',
    user_phone_number: user?.phone || '',
    user_hashed_email: '',
    user_hashed_phone_number: '',
  });
};

const Routes = () => {
  const { state } = useUserProvider();

  const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

  if (gaId) ReactGA.initialize(gaId);

  useCurrentUser();

  const location = useLocation();

  React.useEffect(() => {
    if (gaId) ReactGA.pageview(location.pathname + location.search);
    // @ts-ignore
    // if (window.fbq && !location.pathname.includes('/product/')) {
    //   ReactPixel.pageView();
    // }
    // @ts-ignore
    if (window.snaptr) snapPixelPageViewHandler(state.user);
    // @ts-ignore
    if (window.dataLayer) googleTagsPageViewHandler();

    TiktokPixel.track('PageView', {
      currency: 'EUR',
      content_id: window.location.href,
      value: 0,
      content_type: 'product',
      description: window.location.href,
    });
  }, [location]);

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/checkout" exact component={Checkout} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/thank-you" exact component={ThankYou} />
      <Route path="/my-designs/" exact component={CustomizedProducts} />
      <Route path="/product/:slug" exact component={RegularProduct} />
      <Route path="/category/:slug" exact component={Category} />
      <Route path="/my-designs/:id" exact component={CustomizedProduct} />
      <Route
        path="/collection/:collectionName"
        exact
        component={CollectionAllCategories}
      />
      <Route
        path="/collection/:collection/:category"
        exact
        component={Collection}
      />
      <Route
        exact
        path="/product-customize-edit/:id/:size"
        component={EditSession}
      />
      <Route
        exact
        path="/product-customize-create/:slug/:size"
        component={CreationSession}
      />

      <Route path="/special-event/" exact component={SpecialEvent} />
      <Route path="/kiosk-service" exact component={KioskService} />

      <WithAuthRoute authState path="/account" exact component={Account} />

      <Route path="/reset-password" exact component={CreateNewPassword} />

      <Route path="/faq" component={Static} />
      <Route path="/betalen" component={Static} />
      <Route path="/carriere" component={Static} />
      <Route path="/retouren" component={Static} />
      <Route path="/bestellen" component={Static} />
      <Route path="/verzenden" component={Static} />
      <Route path="/disclaimer" component={Static} />
      <Route path="/ons-verhaal" component={Static} />
      <Route path="/sweater-men" component={Static} />
      <Route path="/cookiebeleid" component={Static} />
      <Route path="/privacybeleid" component={Static} />
      <Route path="/tracksuit-heren" component={Static} />
      <Route path="/streetwear-heren" component={Static} />
      <Route path="/streetwear-heren" component={Static} />
      <Route path="/streetwear-merken" component={Static} />
      <Route path="/custom-spijkerjas" component={Static} />
      <Route path="/streetwear-kleding" component={Static} />
      <Route path="/media-ambassadeurs" component={Static} />
      <Route path="/custom-made-kleding" component={Static} />
      <Route path="/trui-personaliseren" component={Static} />
      <Route path="/algemene-voorwaarden" component={Static} />
      <Route path="/samplesale" component={Static} />

      <Route path="*" component={Page404} />
    </Switch>
  );
};

export default Routes;
